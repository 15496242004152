/* eslint-disable no-param-reassign */

import {orderBy} from 'lodash';

const moment = require('moment');

const formatTime = (time) => {
  if (!time || !time?.length) {
    return new Date();
  }
  const timeString = time?.split(' ')[0];
  if (timeString?.length) {
    return new Date();
  }
  // eslint-disable-next-line prefer-const
  let [hours, minutes, seconds] = timeString?.split(':') ?? [0, 0, 0];
  const newDate = new Date();

  if (time?.includes('PM')) {
    if (hours !== '12') {
      hours = 12 + Number(hours);
    } else {
      hours = 12;
    }
  } else if (time?.includes('AM')) {
    if (hours === '12') {
      hours = 0;
    } else {
      hours = Number(hours);
    }
  }

  newDate.setHours(hours);
  newDate.setMinutes(minutes);
  newDate.setSeconds(seconds);
  return newDate;
};

const formatDate = (date) => moment(date, 'MM/DD/YYYY').toDate();

const formatColumns = (columnValue, filterType, columnOrderBy, optionsColumns) => {
  const value = columnValue ? columnValue[columnOrderBy]?.value ?? columnValue[columnOrderBy] : null;
  if ((value === null || value === undefined) && filterType?.type !== 'pop-out') {
    return value;
  }

  if (filterType?.type === 'pop-out') {
    if (filterType?.key && optionsColumns?.[filterType?.key]?.columns?.length) {
      return Object.keys(columnValue ?? {}).some((r) => optionsColumns[filterType?.key]?.columns?.includes(r));
    }
    return false;
  }

  if (filterType?.convertTo === 'time') {
    return formatTime(value);
  }

  if (filterType?.convertTo === 'date') {
    return formatDate(value);
  }

  if (filterType?.type === 'number') {
    return Number(value);
  }

  if (filterType?.type === 'boolean') {
    if (!Number.isNaN(Number(value))) {
      return value > 0;
    } 
    if (typeof value === 'boolean') {
      return value;
    }
    
    if (typeof value === 'string') {
      return value.toLowerCase() === 'true';
    }

    return value;
  }

  return value;
};

const formatJoinedColumns = (columnValue, filterType, optionsColumns, returnObject = false) => {
  const columnDataTypes = {
    columnA: null,
    columnB: null,
    columnC: null,
  };
  const allowedFilterTypes = ['number', 'date', 'time', 'boolean', 'pop-out', 'string'];

  const {columnA, columnADataType, columnB, columnBDataType, columnC, columnCDataType} = filterType ?? {
    columnA: null,
    columnADataType: null,
    columnB: null,
    columnBDataType: null,
    columnC: null,
    columnCDataType: null,
  };

  if (columnADataType && allowedFilterTypes.includes(columnADataType?.type)) {
    columnDataTypes.columnA = formatColumns(columnValue, columnADataType, columnA, optionsColumns) ?? '';
  }
  if (columnBDataType && allowedFilterTypes.includes(columnBDataType?.type)) {
    columnDataTypes.columnB = formatColumns(columnValue, columnBDataType, columnB, optionsColumns) ?? '';
  }
  if (columnCDataType && allowedFilterTypes.includes(columnCDataType?.type)) {
    columnDataTypes.columnC = formatColumns(columnValue, columnCDataType, columnC, optionsColumns) ?? '';
  }
  if (returnObject) {
    return columnDataTypes;
  }

  // return Object.values(columnDataTypes);
  return columnDataTypes;
};

const compare = (a, b) => {
  if (a === b) {
    return 0;
  }
  if (!a) {
    return 1;
  }
  if (!b) {
    return -1;
  }

  if (b < a) {
    return -1;
  }

  if (b > a) {
    return 1;
  }
  return 0;
};

const compareJoinedColumns = (a, b) => {
  const [columnA1, columnA2, columnA3] = a;
  const [columnB1, columnB2, columnB3] = b;
  let AB = 0;
  let BA = 0;
  if (columnA1 !== columnB1) {
    AB = compare(columnA1, columnB1);
    BA = compare(columnB1, columnA1);
  }
  if (AB !== 0) {
    return AB;
  }
  if (columnA2 !== columnB2) {
    AB = compare(columnA2, columnB2);
    BA = compare(columnB2, columnA2);
  }
  if (AB !== 0) {
    return AB;
  }
  if (columnA3 !== columnB3) {
    AB = compare(columnA3, columnB3);
    BA = compare(columnB3, columnA3);
  }
  if (AB !== 0) {
    return AB;
  }
  return BA;
};

/**
 * Comperator function for sorting the table by the given column.
 *  DEPRECATED: Use lodash orderBy instead.
 * @param {Object} a
 * @param {Object} b
 * @param {Boolean} orderBy
 * @returns
 */
const descendingComparator = (aValue, bValue, columnOrderBy, filterType, optionsColumns) => {
  if (filterType?.type === 'joined-column') {
    const a = formatJoinedColumns(aValue, filterType, optionsColumns);
    const b = formatJoinedColumns(bValue, filterType, optionsColumns);
    return compareJoinedColumns(a, b);
  }
  const a = formatColumns(aValue, filterType, columnOrderBy, optionsColumns);
  const b = formatColumns(bValue, filterType, columnOrderBy, optionsColumns);

  return compare(a, b);
};

const rankColumnPriority = {
  ALLVALUES: 111000,
  HASA: 100000,
  HASB: 10000,
  HASC: 1000,
  COLUMNPOSITIVE: 0.05,
  COLUMNNEGATIVE: -0.05,
  MISSINGALL: '',
};

export const sortByColumnPriority = (o, columnOrderBy, filterType, optionsColumns) => {
  if ((!o?.[columnOrderBy] || !Object.keys(o?.[columnOrderBy] ?? {})?.length) && filterType?.type !== 'pop-out') {
    return rankColumnPriority.MISSINGALL;
  }
  if (filterType?.type === 'joined-column') {
    const formattedValue = formatJoinedColumns(o, filterType, optionsColumns, true);
    const {columnA, columnB, columnC} = formattedValue ?? {
      columnA: '',
      columnB: '',
      columnC: '',
    };
    let value = 0;

    const columnAIsBooleanOrNumber = ['boolean', 'number'].includes(filterType?.columnADataType?.type);
    const columnBIsBooleanOrNumber = ['boolean', 'number'].includes(filterType?.columnBDataType?.type);
    const columnCIsBooleanOrNumber = ['boolean', 'number'].includes(filterType?.columnCDataType?.type);

    if (!columnA && !columnB && !columnC) {
      return rankColumnPriority.MISSINGALL;
    }

    if ((!columnC && !filterType?.columnC)|| columnC !== '') {
      value += rankColumnPriority.HASC;
      if (columnCIsBooleanOrNumber) {
        if (filterType?.columnCDataType?.type === 'number' && !Number.isNaN(Number(columnC))) {
          value += columnC;
        } else if (filterType?.columnCDataType?.type === 'boolean') {
          value += !columnC ? rankColumnPriority.COLUMNNEGATIVE : rankColumnPriority.COLUMNPOSITIVE;
        }
      }
    }

    if (columnB !== '') {
      value += rankColumnPriority.HASB;
      if (columnBIsBooleanOrNumber) {
        if (filterType?.columnBDataType?.type === 'number' && !Number.isNaN(Number(columnB))) {
          value += columnB;
        } else if (filterType?.columnBDataType?.type === 'boolean') {
          value += !columnB ? rankColumnPriority.COLUMNNEGATIVE : rankColumnPriority.COLUMNPOSITIVE;
        }
      }
    }

    if (columnA) {
      value += rankColumnPriority.HASA;
      if (columnAIsBooleanOrNumber) {
        if (filterType?.columnADataType?.type === 'number' && !Number.isNaN(Number(columnA))) {
          value += columnA;
        } else if (filterType?.columnADataType?.type === 'boolean') {
          value += !columnA ? rankColumnPriority.COLUMNNEGATIVE : rankColumnPriority.COLUMNPOSITIVE;
        }
      }
    }

    return value;
  }
  return formatColumns(o, filterType, columnOrderBy, optionsColumns);
};

/**
 * IE11 Compatable Sort
 *
 * @param {Array} array
 * @param {Function} comparator
 * @returns
 */
export const stableSort = (array, order, columnOrderBy, filterType, optionsColumns) => {
  const lodashSort = orderBy(array, (o) => sortByColumnPriority(o, columnOrderBy, filterType, optionsColumns), [order]);
  return lodashSort;

  // DEPRECATED: Use lodash orderBy instead.
  // const stabilizedThis = array.map((el, index) => [el, index]);
  // stabilizedThis.sort((a, b) => {
  //   const order = comparator(a[0], b[0]);
  //   if (order !== 0) {
  //     return order;
  //   }
  //   return a[1] - b[1];
  // });
  // return stabilizedThis.map((el) => el[0]);
};